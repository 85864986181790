.newSection {
    text-align: center;
}

.newSectionContainer {
    display: flex;
    padding: 112px 64px;
    gap: 80px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../../assets/images/no-image.jpg);
    border-radius: 0px;
    background-size: cover;
    background-position: center;
    height: 413px;
}

.sectionTitle,
.sectionSubtitle {
    color: white;
}

.sectionTitle {
    font-weight: 600 !important;
}

.sectionSubtitle {
    font-size: 1rem !important;
}

.sectionButtons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
}

.contact {
    width: 115px;
    height: 48px;
    border-radius: 0 !important;
    font-size: 1rem !important;
    background-color: #6cde04 !important;
    box-shadow: none !important;
    color: #000 !important;
}

.contact:hover {
    box-shadow: 0 0 10px #6cde0450, 0 0 20px #6cde0490, 0 0 30px #6cde0450, 0 0 40px #6cde0450 !important;
}

@media (max-width: 900px) {
    .newSectionContainer {
        display: flex;
        padding: 75px 64px;
    }
}