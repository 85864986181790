.background {
    height: calc(100vh - 90px);
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 0 !important;
}

.background::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(190.6deg, rgba(0, 0, 0, 0) 52.77%, #00000075 88.69%),
        linear-gradient(98.32deg, #00000075 36.97%, rgba(0, 0, 0, 0) 75.24%),
        linear-gradient(182.15deg, #00000075 1.01%, rgba(0, 0, 0, 0) 21.71%),
        linear-gradient(272.11deg, #00000075 -1.92%, rgba(0, 0, 0, 0) 28.9%),
        green
        url(../../../assets/images/background.png) no-repeat center / 100%;
    filter: saturate(2) brightness(0.7) contrast(1.1);
    z-index: -1;
}

.box {
    color: white;
    width: 55%;
    padding: 16px;
    text-align: left;
}

.title {
    text-transform: uppercase;
    font-size: 2rem;
    /* Base font size */
}

.highlight {
    font-weight: 900;
    color: #6cde04;
}

.emphasis {
    font-weight: 900;
}

.subtitle {
    font-weight: 400;
    font-size: 1.2rem;
    /* Base font size */
}

.css-1oqqzyl-MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.actionButton {
    font-weight: 600 !important;
    background-color: #6cde04 !important;
    border-radius: 0 !important;
    padding: 8px 20px !important;
    margin-top: 35px !important;
    box-shadow: 0 0 20px #6cde0450, 0 0 40px #6cde0499, 0 0 60px #6cde0450, 0 0 80px #6cde0450 !important;
    font-size: 2rem !important;
    /* Base font size */
    transition: background-color 0.3s, box-shadow 0.3s !important;
}


.actionButton:hover {
    box-shadow: 0 0 20px #6cde0499, 0 0 40px #6cde0499, 0 0 60px #6cde0490, 0 0 80px #6cde0490 !important;
}

@media (max-width: 900px) {
    .box {
        width: 89%;
    }
    
    .css-1oqqzyl-MuiContainer-root {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .background::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(190.6deg, rgba(0, 0, 0, 0) 52.77%, #00000075 88.69%),
            linear-gradient(98.32deg, #00000075 36.97%, rgba(0, 0, 0, 0) 75.24%),
            linear-gradient(182.15deg, #00000075 1.01%, rgba(0, 0, 0, 0) 21.71%),
            linear-gradient(272.11deg, #00000075 -1.92%, rgba(0, 0, 0, 0) 28.9%),
            green
            url(../../../assets/images/background.png) no-repeat center / 200%;
        filter: saturate(2) brightness(0.7) contrast(1.1);
        z-index: -1;
    }
}

@media (max-width: 600px) {
    .box {
        width: 100%;
        margin: 0 auto;
        padding: 8px !important;
        text-align: center;
    }

    .title {
        font-size: 2rem !important;
    }

    .subtitle {
        font-size: 1.25rem !important;
    }
    .css-1oqqzyl-MuiContainer-root {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .actionButton {
        font-size: 1.5rem !important;
        line-height: 30px !important;
    }
    .background::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(190.6deg, rgba(0, 0, 0, 0) 52.77%, #00000075 88.69%),
            linear-gradient(98.32deg, #00000075 36.97%, rgba(0, 0, 0, 0) 75.24%),
            linear-gradient(182.15deg, #00000075 1.01%, rgba(0, 0, 0, 0) 21.71%),
            linear-gradient(272.11deg, #00000075 -1.92%, rgba(0, 0, 0, 0) 28.9%),
            green
            url(../../../assets/images/background.png) no-repeat -400px / 250%;
        filter: saturate(2) brightness(0.7) contrast(1.1);
        z-index: -1;
    }
}