/* Adicione isso ao seu arquivo CSS */
.map-container {
    position: relative;
    overflow: hidden;
    width: 100%; /* Garante que por padrão, ocupe 100% da largura */
      height: 460px; 
      /* Define a altura baseada na proporção de aspecto */
    padding-top: 29%; 
  }
  
  @media (min-width: 1024px) { /* Para dispositivos com largura maior que 1024px */
    .map-container {
      width: 1200px; /* Largura fixa para desktops */
      height: 460px; 
      margin: 0 auto; /* Centraliza o contêiner */
    }
  }
  