/* Personalizações do Slick Slider */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.carouselImageContainer {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
}

.carouselImageContainer img {
    width: 100%;
    height: 413px;
    object-fit: cover;
}

.slick-slide {
    padding: 0 20px 0 0;
}

/* Personalização dos dots */
.slick-dots {
    text-align: left;
}

.slick-dots li {
    margin: 0;
}

.slick-dots li button:before {
    background-color: transparent;
    color: #ffffff;
    font-size: 9px;
    margin-top: 40px !important;
}

.slick-dots li.slick-active button:before {
    background-color: transparent;
    color: #ffffff;
}

@media (max-width: 992px){
    .carouselImageContainer img {
        height: 272px;
    }
}