.appBar {
}

.container {
}

.toolbar {
  justify-content: space-between;
  height: 100%;
}

.logoBox {
  display: grid;
  place-items: center;
}

.logoTypography {
  flexGrow: 1;
  color: var(--text-primary-color); /* Make sure to define this variable in your CSS or replace it with a concrete value */
  font-weight: 700;
}

.logoImage {
  height: 40px;
  margin-top: 10px;
}

.navButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-primary-color); /* Make sure to define this variable in your CSS or replace it with a concrete value */
}

.navButton {
  font-weight: 500 !important;
  color: #000 !important;
}

.actionBox {
  display: grid;
  place-items: center;
}
