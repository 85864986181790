.btnAnimatedContainer {
  width: 115px ;
}  

.btnAnimatedCenter {
  width: 115px !important;
  height: 48px;
  position: absolute;
}

.btnAnimated {
  width: 115px;
  height: 48px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #6cde04;
  outline: none;
  transition: 1s ease-in-out;
  color: white;
}

.btnAnimated svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: #cfffa3;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: .5s ease-in-out;
}

.btnAnimated:hover {
  transition: .5s ease-in-out;
  background: #6cde04;
  color: #000 !important;
}

.btnAnimated:hover svg {
  stroke-dashoffset: -480;
}

.btnAnimated span {
  font-size: 1rem !important;
}