@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap");


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Red Hat Display", sans-serif !important;
}
body {
  overflow-x: hidden;
  background-color: #000;
}
